// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/backgrounds/bg-circle-triangle.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/backgrounds/bg-half-circle.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/backgrounds/bg-small-half-circle.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.module-no-balance[data-v-493b7197] {\n  min-height: 352px;\n}\n.empty-network-balance[data-v-493b7197] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: right 60px bottom -1px;\n  background-size: 180px;\n  left: 0;\n  top: 0;\n}\n.empty-token-list[data-v-493b7197] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-position: right -16px bottom -26px, left -18px bottom -29px;\n  background-size: 357px, 150px;\n  border-radius: 12px;\n}\n", ""]);
// Exports
module.exports = exports;
