var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew6-white-sheet",
    {
      class: [
        _vm.isTokens ? "empty-token-list" : "empty-network-balance",
        "module-no-balance pa-4 py-7 pa-sm-12",
      ],
      attrs: { color: "white", "max-width": "100%" },
    },
    [
      !_vm.isTokens
        ? _c("div", [
            _c("h2", { staticClass: "mb-6" }, [
              _vm._v("My " + _vm._s(_vm.networkType) + " balance is empty"),
            ]),
            _vm.isEth
              ? _c(
                  "div",
                  [
                    _c("mew-button", {
                      attrs: {
                        "has-full-width": false,
                        title: "Buy ETH with a credit card",
                        "btn-size": "xlarge",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.openBuySell.apply(null, arguments)
                        },
                      },
                    }),
                    _c("div", { staticClass: "d-flex align-center mt-5" }, [
                      _c("div", [_vm._v("We accept credit card")]),
                      !_vm.$vuetify.theme.dark
                        ? _c("img", {
                            staticClass: "ml-2 mr-1",
                            attrs: {
                              height: "21",
                              src: require("@/assets/images/icons/icon-visa-dark.png"),
                            },
                          })
                        : _vm._e(),
                      _vm.$vuetify.theme.dark
                        ? _c("img", {
                            staticClass: "ml-2 mr-2",
                            attrs: {
                              height: "13",
                              src: require("@/assets/images/icons/icon-visa-white.png"),
                            },
                          })
                        : _vm._e(),
                      _c("img", {
                        attrs: {
                          height: "18",
                          src: require("@/assets/images/icons/icon-mastercard-mew.png"),
                        },
                      }),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "textLight--text mt-12" }, [
              _vm._v(
                " Tip: You can also send your " +
                  _vm._s(_vm.networkType) +
                  " from another wallet! "
              ),
            ]),
          ])
        : _c(
            "div",
            [
              _c("h2", { staticClass: "mb-6" }, [
                _vm._v("My token list is empty"),
              ]),
              _vm.isEth
                ? _c("mew-button", {
                    staticClass: "ml-auto ml-n3",
                    attrs: {
                      "has-full-width": false,
                      title: "+ Buy ERC20 tokens",
                      "btn-size": "xsmall",
                      "btn-style": "transparent",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.navigateToSwap.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _c("br"),
              _c("mew-button", {
                staticClass: "ml-auto ml-n3",
                attrs: {
                  "has-full-width": false,
                  title: "+ Add a custom token",
                  "btn-size": "xsmall",
                  "btn-style": "transparent",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openCustomTokens.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }